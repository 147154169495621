import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AccountsAdvancedSearch from '../../components/AccountsAdvancedSearch/AccountsAdvancedSearch';
import AccountsBasicSearch from '../../components/AccountsBasicSearch/AccountsBasicSearch';
import AccountsList from '../../components/AccountsList/AccountsList';
import { IFilterPossibility } from '../../components/AccountsList/IAccountsListFilter';
import { CURRENT_STEPS } from '../../constants/CurrentStepsConstant';
import IUserInvestmentAccountInformationsParams from '../../interfaces/IUserInvestmentAccountInformationsParams';
import AccountsService from '../../services/AccountsService';
import { createParamsObjectFromQuerystring } from '../../utils/createParamsObjectFromQueryString';
import { IAccountsPageState } from './IAccountsPageState';

const styles: { [key: string]: SxProps } = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
};

class AccountsPage extends React.PureComponent<RouteComponentProps, IAccountsPageState> {
  private readonly abortController = new AbortController();
  private readonly accountsService: AccountsService;
  private readonly basicSearchRef: React.RefObject<AccountsBasicSearch>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.basicSearchRef = React.createRef();
    this.accountsService = new AccountsService(this.abortController.signal);
    const params = createParamsObjectFromQuerystring();
    const limit = params.get('limit');
    const page = params.get('page');
    const isOrderedBy = Object.keys(params).find((property) => property.startsWith('order[')) as IFilterPossibility | undefined;
    const order = isOrderedBy ? params.get(isOrderedBy) as 'asc' | 'desc' : 'asc';

    this.state = {
      tableList: {
        isLoading: false,
        rowsPerPage: limit && parseInt(limit) ? parseInt(limit) : 25,
        page: page && parseInt(page) ? parseInt(page) : 0,
        totalAccounts: 0,
        order,
        orderBy: isOrderedBy ?? '',
      },
      accounts: [],
      params,
    };
  }

  public async componentDidMount() {
    try {
      const params = createParamsObjectFromQuerystring();
      const paramsIsEmpty = 0 === params.size;

      if (paramsIsEmpty) {
        const { tableList: { rowsPerPage, page } } = this.state;

        params.append('limit', rowsPerPage.toString());
        params.append('page', (page + 1).toString());
        this.updateUrl(params);
      }

      if (!paramsIsEmpty) {
        if (this.shouldGetAccounts(params)) {
          await this.getUserInvestmentAccountInformations(params);
        }
      }
    } catch (error) {
      console.debug(error);
    }
  }

  public componentWillUnmount() {
    this.abortController.abort();
  }

  public render() {
    const { tableList, accounts } = this.state;

    return (
      <Box sx={styles.wrapper}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1 }} padding={1}>
            <AccountsBasicSearch ref={this.basicSearchRef} getUserInvestmentAccountInformations={this.getUserInvestmentAccountInformations} />
          </Box>
          <Box>
            <AccountsAdvancedSearch updateUrl={this.updateUrl} getUserInvestmentAccountInformations={this.getUserInvestmentAccountInformations} />
          </Box>
        </Box>
        <AccountsList
          accounts={accounts}
          {...tableList}
          onTableListChange={this.onTableListChange}
        />
      </Box>
    );
  }

  private updateUrl = (params: URLSearchParams) => {
    const { history } = this.props;
    let urlString = '?';
    const numberOfParams = params.size;
    let index = 0;


    params.forEach((param, key) => {
      urlString += `${ key }=${ param }`;

      if (index < numberOfParams - 1) {
        urlString += '&';
      }

      ++index;
    });

    if (history.location.search.slice(1) !== params.toString()) {
      history.replace({
        pathname: history.location.pathname,
        search: urlString,
      });
    }
  };

  private getUserInvestmentAccountInformations = async (newParams: URLSearchParams) => {
    const paramsCopy = new URLSearchParams(createParamsObjectFromQuerystring());
    const paramsToAppend = ['currentStep[]', 'advisorId[]'];
    let newParamsCurrentSteps = newParams.getAll('currentStep[]');
    const paramsCopyCurrentSteps = paramsCopy.getAll('currentStep[]');

    if (newParamsCurrentSteps.length === 1 && newParamsCurrentSteps[0] === CURRENT_STEPS.END) {
      paramsCopy.forEach((_, key) => {
        if (key === 'currentStep[]') {
          paramsCopy.delete(key);
        }
      });
    }

    if (newParamsCurrentSteps[0] === '') {
      newParams.delete('currentStep[]');

      if (paramsCopyCurrentSteps.length === 1 && paramsCopyCurrentSteps[0] === CURRENT_STEPS.END) {
        paramsCopy.delete('currentStep[]');
      }
    }

    newParams.forEach((value, key) => {
      if (!value) {
        return paramsCopy.delete(key);
      }

      if (paramsToAppend.includes(key)) {
        !paramsCopy.has(key, value) && paramsCopy.append(key, value);
      } else {
        paramsCopy.set(key, value);
      }
    });

    if (!this.shouldGetAccounts(paramsCopy)) {
      this.updateUrl(paramsCopy);

      return this.setState({
        tableList: {
          isLoading: false,
          rowsPerPage: 25,
          page: 0,
          totalAccounts: 0,
          order: 'asc',
          orderBy: '',
        },
        accounts: [],
        params: new URLSearchParams({}),
      });
    }

    this.setState((oldState) => ({
      tableList: {
        ...oldState.tableList,
        isLoading: true,
      },
      params: paramsCopy,
    }));

    newParamsCurrentSteps = newParams.getAll('currentStep[]');

    if (this.basicSearchRef.current) {
      this.basicSearchRef.current.setState({
        clientsOnly: newParamsCurrentSteps.length === 1 && newParamsCurrentSteps[0] === CURRENT_STEPS.END,
      });
    }

    try {
      const accountId = paramsCopy.get('accountId');

      if (accountId) {
        this.updateUrl(paramsCopy);
        const response = await this.accountsService.getUserInvestmentAccountInformation(accountId);

        if (this.shouldGetAccounts(createParamsObjectFromQuerystring())) {
          this.setState((oldTableList) => ({
            tableList: {
              ...oldTableList.tableList,
              isLoading: false,
              totalAccounts: 1,
              page: 0,
            },
            accounts: [response.data],
          }));
        } else {
          this.resetState();
        }
      } else {
        this.updateUrl(paramsCopy);
        const response = await this.accountsService.getUserInvestmentAccountInformationCollection(paramsCopy);

        if (this.shouldGetAccounts(createParamsObjectFromQuerystring())) {
          const accounts = response.data['hydra:member'];

          this.setState((oldTableList) => ({
            tableList: {
              ...oldTableList.tableList,
              isLoading: false,
              totalAccounts: response.data['hydra:totalItems'],
              page: (accounts.length > 0 && paramsCopy.get('page')) ? (parseInt(paramsCopy.get('page') as string) - 1) : 0,
            },
            accounts,
          }));
        } else {
          this.resetState();
        }
      }
    } catch (error) {
      this.resetState();
    }
  };

  private resetState = () => {
    this.setState({
      tableList: {
        isLoading: false,
        rowsPerPage: 25,
        page: 0,
        totalAccounts: 0,
        order: 'asc',
        orderBy: '',
      },
      accounts: [],
      params: new URLSearchParams({}),
    });
  };

  private onTableListChange = async (newTableList: Partial<IAccountsPageState['tableList']>) => {
    try {
      const { tableList: { isLoading, orderBy, order }, accounts } = this.state;

      if (isLoading) {
        return;
      }

      const params = createParamsObjectFromQuerystring();

      for (const pairAndValue of Object.entries(newTableList)) {
        const pair = pairAndValue[0] as keyof IUserInvestmentAccountInformationsParams | 'rowsPerPage' | 'order' | 'orderBy';
        const value = pairAndValue[1];

        if (pair === 'rowsPerPage') {
          params.set('limit', value.toString());
        } else if (pair === 'page') {
          params.set('page', (+value + 1).toString());
        } else if (pair !== 'order' && pair !== 'orderBy') {
          params.set(pair, value.toString());
        }
      }

      if (newTableList.orderBy) {
        if (orderBy) {
          params.delete(`order[${ orderBy }]`);
        }
        params.set(`order[${ newTableList.orderBy }]`, newTableList.order || order);
      }

      this.updateUrl(params);

      this.setState(
        ({ tableList }) => ({
          tableList: {
            ...tableList,
            ...newTableList,
          },
          params,
        }),
        () => accounts.length > 0 && this.getUserInvestmentAccountInformations(params),
      );
    } catch (error) {
      console.debug(error);
    }
  };

  private shouldGetAccounts(params: URLSearchParams): boolean {
    const nonTriggeringKeys = ['limit', 'page'];
    const paramKeys = Array.from(params.keys());

    return paramKeys.filter((key) => !nonTriggeringKeys.includes(key)).length > 0;
  }
}

export default withRouter(AccountsPage);
