import { AxiosResponse } from 'axios';
import { IRIString } from '../interfaces/IriType';
import ServiceConstructor from './ServiceConstructor';

class OrderService extends ServiceConstructor {
  public resumeRequest(requestIri: IRIString): Promise<AxiosResponse> {
    return this.postRessource<unknown, Record<string, never>>(
      `${ requestIri }/resume`,
      {},
    );
  }

  public deleteRequest(requestIri: IRIString): Promise<AxiosResponse> {
    return this.deleteRessource(requestIri);
  }
}

export default OrderService;
