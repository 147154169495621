import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TabIcon from '@mui/icons-material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import WindowNavigationService from '../../services/WindowNavigationService';
import IAccountsListMenuProps from './IAccountsListMenuProps';

class AccountsListMenu extends React.Component<IAccountsListMenuProps> {
  private windowNavigationService = new WindowNavigationService();
  private menuItems = [{
    content: 'account_list_menu_open',
    icon: null,
    onClick: (event: React.MouseEvent) => {
      event.preventDefault();
      const { history, accountId } = this.props;

      history.push(`/accounts/${ accountId }/client-info`);
    },
  }, {
    content: 'account_list_menu_open_in_new_tab',
    icon: <TabIcon fontSize="small" sx={{ marginLeft: '6px' }} />,
    onClick: (event: React.MouseEvent) => {
      event.stopPropagation();
      const { accountId, handleClose } = this.props;

      this.windowNavigationService.openInNewTab(`/accounts/${ accountId }/client-info`);
      handleClose(event);
    },
  }, {
    content: 'account_list_menu_open_in_new_window',
    icon: <OpenInNewIcon fontSize="small" sx={{ marginLeft: '6px' }} />,
    onClick: (event: React.MouseEvent) => {
      event.stopPropagation();
      const { accountId, handleClose } = this.props;

      this.windowNavigationService.openInNewWindow(`/accounts/${ accountId }/client-info`);
      handleClose(event);
    },
  }];

  public render(): JSX.Element {
    const { anchorEl, handleClose } = this.props;
    const open = Boolean(anchorEl);

    return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          this.menuItems.map((menuItem) => (
            <MenuItem onClick={menuItem.onClick} key={menuItem.content}>
              <Trans>{ menuItem.content }</Trans>
              { menuItem.icon }
            </MenuItem>
          ))
        }
      </Menu>
    );
  }
}

export default withRouter(AccountsListMenu);
