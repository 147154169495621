import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { SxProps } from '@mui/material/styles';
import { t } from 'i18next';
import React from 'react';
import { CURRENT_STEPS } from '../../constants/CurrentStepsConstant';
import { createParamsObjectFromQuerystring } from '../../utils/createParamsObjectFromQueryString';
import { IAccountsBasicSearchProps } from './IAccountsBasicSearchProps';
import { IAccountsBasicSearchState } from './IAccountsBasicSearchState';

const styles: { [key: string]: SxProps } = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
};

class AccountsBasicSearch extends React.Component<IAccountsBasicSearchProps, IAccountsBasicSearchState> {
  private debounceTimeout?: NodeJS.Timeout;
  private accountIdRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

  constructor(props: IAccountsBasicSearchProps) {
    super(props);

    const params = createParamsObjectFromQuerystring();
    const accountIdValue = params.get('accountId') ?? '';
    const currentStepParams = params.getAll('currentStep[]');

    this.state = {
      email: params.get('email') ?? '',
      accountId: accountIdValue,
      lastname: params.get('lastname') ?? '',
      firstname: params.get('firstname') ?? '',
      couponCode: params.get('couponCode') ?? '',
      clientsOnly: !!currentStepParams && currentStepParams.length === 1 && currentStepParams[0] === CURRENT_STEPS.END,
      isAccountIdValid: this.accountIdIsValid(accountIdValue),
    };
  }

  public render() {
    const { email, accountId, lastname, firstname, couponCode, clientsOnly, isAccountIdValid } = this.state;

    return (
      <Box sx={styles.wrapper}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Card sx={{ flex: 1 }} elevation={0}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  autoFocus
                  label={t('accounts_list_email')}
                  variant="outlined"
                  size="small"
                  name="email"
                  value={email}
                  sx={this.focusStyles('email')}
                  onChange={this.handleQuickSearchFieldChange}
                  InputProps={{ endAdornment: this.renderClearAdornment('email') }}
                />
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  fullWidth
                  label="Account Id"
                  variant="outlined"
                  size="small"
                  name="accountId"
                  value={accountId}
                  helperText={!!accountId && !isAccountIdValid && t('invalid_account_id')}
                  error={!!accountId && !isAccountIdValid}
                  sx={this.focusStyles('accountId')}
                  onChange={this.handleQuickSearchFieldChange}
                  InputProps={{ endAdornment: this.renderClearAdornment('accountId') }}
                />
                <Tooltip title={t('account_id_field_tooltip')}>
                  <HelpOutlineOutlinedIcon color="disabled" />
                </Tooltip>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={t('account_identity_lastname_label')}
                  variant="outlined"
                  size="small"
                  name="lastname"
                  value={lastname}
                  sx={this.focusStyles('lastname')}
                  onChange={this.handleQuickSearchFieldChange}
                  InputProps={{ endAdornment: this.renderClearAdornment('lastname') }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={t('account_identity_firstname_label')}
                  variant="outlined"
                  size="small"
                  name="firstname"
                  value={firstname}
                  sx={this.focusStyles('firstname')}
                  onChange={this.handleQuickSearchFieldChange}
                  InputProps={{ endAdornment: this.renderClearAdornment('firstname') }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label={t('coupon')}
                  variant="outlined"
                  size="small"
                  name="couponCode"
                  value={couponCode}
                  sx={this.focusStyles('couponCode')}
                  onChange={this.handleQuickSearchFieldChange}
                  InputProps={{ endAdornment: this.renderClearAdornment('couponCode') }}
                />
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', alignContent: 'center' }}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={clientsOnly}
                      onChange={this.handleQuickSearchFieldChange}
                      name="clientsOnly"
                    />
                  )}
                  label={t('customers_only')}
                />
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Box>
    );
  }

  private applyFilters = () => {
    const { accountId, isAccountIdValid } = this.state;

    if (accountId && !isAccountIdValid) {
      return;
    }

    const { getUserInvestmentAccountInformations } = this.props;
    const { clientsOnly, couponCode, email, lastname, firstname } = this.state;
    const params = new URLSearchParams({});

    for (const [key, value] of Object.entries({ accountId, couponCode, email, lastname, firstname, clientsOnly })) {
      if ('clientsOnly' === key) {
        params.append('currentStep[]', value ? CURRENT_STEPS.END : '');
      } else {
        params.append(key, value as string);
      }
    }

    getUserInvestmentAccountInformations(params);
  };

  private hasUrlParam = (name: string) => {
    const params = createParamsObjectFromQuerystring();

    if (params.has('accountId')) {
      return name === 'accountId';
    } else {
      return params.has(name);
    }
  };

  private focusStyles = (name: string) => this.hasUrlParam(name) ? {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary.main',
        borderWidth: '2px',
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: 'primary.main',
    },
  } : undefined;

  private handleQuickSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    const hasChangedCheckboxValue = 'clientsOnly' === name;

    this.setState((prevData) => ({
      ...prevData,
      [name]: hasChangedCheckboxValue ? checked : value,
    }));

    if ('accountId' === name) {
      const accountIdIsValid = this.accountIdIsValid(value);
      const { isAccountIdValid } = this.state;

      if (isAccountIdValid !== accountIdIsValid) {
        this.setState({
          isAccountIdValid: accountIdIsValid,
        });
      }
    }

    const timeOut = hasChangedCheckboxValue || ('accountId' === name && !!value && this.accountIdIsValid(value)) ? 0 : 500;

    this.debounceTimeout && clearTimeout(this.debounceTimeout);

    this.debounceTimeout = setTimeout(this.applyFilters, timeOut);
  };

  private renderClearAdornment = (fieldName: Exclude<keyof IAccountsBasicSearchState, 'clientsOnly' | 'isAccountIdValid'>) => {
    const value = this.state[fieldName];

    return value ? (
      <InputAdornment position="end">
        <IconButton onClick={this.clearField(fieldName)} edge="end">
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ) : <></>;
  };

  private clearField = (fieldName: Exclude<keyof IAccountsBasicSearchState, 'clientsOnly' | 'isAccountIdValid'>) => () => {
    this.setState((prevState) => {
      const updatedState = { ...prevState };

      updatedState[fieldName] = '';
      return updatedState;
    }, () => {
      this.debounceTimeout && clearTimeout(this.debounceTimeout);
      this.applyFilters();
    });
  };

  private accountIdIsValid = (value: string): boolean => !!value.match(this.accountIdRegex);
}

export default AccountsBasicSearch;
