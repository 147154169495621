import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, Checkbox, Divider, Drawer, FormControl, FormControlLabel, IconButton, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, SxProps, Tooltip, Typography } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import { t } from 'i18next';
import { ChangeEvent, Component } from 'react';
import { Trans } from 'react-i18next';
import ReferentialContext from '../../contexts/ReferentialContext';
import { IProviders } from '../../interfaces/IProvider';
import { IReferential } from '../../interfaces/IReferential';
import { createParamsObjectFromQuerystring } from '../../utils/createParamsObjectFromQueryString';
import FromDateToDate from '../FromDateToDate/FromDateToDate';
import { IAccountsAdvancedSearchProps } from './IAccountsAdvancedSearchProps';
import { IAccountsAdvancedSearchState } from './IAccountsAdvancedSearchState';

const styles: { [key: string]: SxProps } = {
  formLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  filterSideBarWrapper: {
    height: '100vh',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
};

export default class AccountsAdvancedSearch extends Component<IAccountsAdvancedSearchProps, IAccountsAdvancedSearchState> {
  constructor(props: IAccountsAdvancedSearchProps) {
    super(props);
    const params = createParamsObjectFromQuerystring();
    const signatureDateAfter = params.get('signatureDate[after]');
    const signatureDateBefore = params.get('signatureDate[before]');
    const lastLoginAtAfter = params.get('lastLoginAt[after]');
    const lastLoginAtBefore = params.get('lastLoginAt[before]');
    const videoUploadedAtBefore = params.get('videoUploadedAt[before]');
    const videoUploadedAtAfter = params.get('videoUploadedAt[after]');
    const hidden = !!params.get('hidden');
    const currentSteps = params.getAll('currentStep[]');
    const advisors = params.getAll('advisorId[]');
    const providerContractTypeId = params.get('providerContractTypeId');

    this.state = {
      currentSteps: currentSteps,
      advisors: advisors,
      filtersSideBarIsOpen: false,
      dateFieldsValues: {
        'signatureDate[after]': signatureDateAfter ? new Date(signatureDateAfter) : undefined,
        'signatureDate[before]': signatureDateBefore ? new Date(signatureDateBefore) : undefined,
        'lastLoginAt[after]': lastLoginAtAfter ? new Date(lastLoginAtAfter) : undefined,
        'lastLoginAt[before]': lastLoginAtBefore ? new Date(lastLoginAtBefore) : undefined,
        'videoUploadedAt[before]': videoUploadedAtBefore ? new Date(videoUploadedAtBefore) : undefined,
        'videoUploadedAt[after]': videoUploadedAtAfter ? new Date(videoUploadedAtAfter) : undefined,
      },
      providerContractTypeId: providerContractTypeId ?? '',
      hidden,
    };
  }

  public render() {
    const { currentSteps, dateFieldsValues, hidden, filtersSideBarIsOpen, advisors, providerContractTypeId } = this.state;

    return (
      <>
        <Tooltip title={<Trans>filters</Trans>}>
          <IconButton onClick={() => this.handleFiltersSideBar(true)}>
            <FilterAltIcon fontSize="large" />
          </IconButton>
        </Tooltip>

        <ReferentialContext.Consumer>
          {
            ({ currentSteps: currentStepReferential, advisors: advisorsReferential, providerContractTypes: providerContractTypesReferential, providers }) => {
              const sortedCurrentStepReferential = currentStepReferential.toSorted((stepA, stepB) => (stepA.meta?.['position'] as number) - (stepB.meta?.['position'] as number));

              return (
                <Drawer
                  anchor="right"
                  open={filtersSideBarIsOpen}
                  onClose={() => this.handleFiltersSideBar(false)}
                >
                  <Box sx={styles.filterSideBarWrapper}>
                    <Box sx={styles.formLine}>
                      <Typography padding={2} fontWeight="medium"><Trans>advanced_research</Trans></Typography>
                      <Box>
                        <IconButton onClick={() => this.handleFiltersSideBar(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ padding: 2, overflowY: 'auto', flex: 1 }}>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl sx={{ width: '50%', marginBottom: 2 }}>
                          <InputLabel id="label_current_steps_select_field">
                            <Trans>accounts_list_currentStep</Trans>
                          </InputLabel>
                          <Select
                            labelId="label_current_steps_select_field"
                            value={currentSteps}
                            label={<Trans>accounts_list_currentStep</Trans>}
                            onChange={(event) => this.handleMultipleCheckMarksChange(event, 'currentSteps')}
                            MenuProps={{
                              sx: {
                                maxHeight: 512,
                              },
                            }}
                            renderValue={(selected) => this.getRefLabel(selected, sortedCurrentStepReferential)}
                            multiple
                          >
                            {
                              sortedCurrentStepReferential.map(
                                (currentStep) => (
                                  <MenuItem key={currentStep.slug} value={currentStep.slug}>
                                    <Checkbox checked={!!currentSteps.find((slug) => currentStep.slug === slug)} />
                                    <ListItemText primary={currentStep.label} />
                                  </MenuItem>
                                ),
                              )
                            }
                          </Select>
                        </FormControl>
                        <FormControl sx={{ width: '50%' }}>
                          <InputLabel id="label_advisors_select_field">
                            <Trans>accounts_list_advisor</Trans>
                          </InputLabel>
                          <Select
                            labelId="label_advisors_select_field"
                            value={advisors}
                            label={<Trans>accounts_list_advisor</Trans>}
                            onChange={(event) => this.handleMultipleCheckMarksChange(event, 'advisors')}
                            MenuProps={{
                              sx: {
                                maxHeight: 512,
                              },
                            }}
                            renderValue={
                              (selectedAdvisors: string[]) => advisorsReferential.filter(
                                (advisor) => !!selectedAdvisors.find((selectedAdvisor) => advisor['@id'].split('/').pop() === selectedAdvisor),
                              ).map((advisor) => `${ advisor.firstname } ${ advisor.lastname?.charAt(0) }`).join(', ')
                            }
                            multiple
                          >
                            {
                              advisorsReferential.map(
                                (advisor) => (
                                  <MenuItem key={advisor['@id']} value={advisor['@id'].split('/').pop()} >
                                    <Checkbox checked={!!advisors.find((id) => advisor['@id'].split('/').pop() === id)} />
                                    <ListItemText primary={`${ advisor.firstname } ${ advisor.lastname?.charAt(0) }`} />
                                  </MenuItem>
                                ),
                              )
                            }
                          </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ marginBottom: 2, paddingRight: 2 }}>
                        <FormControl sx={{ width: '50%', maxWidth: '50%' }}>
                          <InputLabel id="label_provider_contract_types_select_field">
                            <Trans>accounts_list_provider_contract_types</Trans>
                          </InputLabel>
                          <Select
                            labelId="label_provider_contract_types_select_field"
                            value={providerContractTypeId}
                            onChange={this.handleSelect}
                            label={<Trans>accounts_list_provider_contract_types</Trans>}
                            MenuProps={{
                              sx: {
                                maxHeight: 512,
                              },
                            }}
                          >
                            <MenuItem value="">
                              <Trans>none</Trans>
                            </MenuItem>
                            {
                              providerContractTypesReferential
                                .sort(
                                  (a, b) => this.getProviderName(a.provider, providers).localeCompare(this.getProviderName(b.provider, providers)),
                                )
                                .map(
                                  (pct) => (
                                    <MenuItem key={pct.id} value={pct.id}>
                                      { pct.name }&nbsp;<small>{ this.getProviderName(pct.provider, providers) }</small>
                                    </MenuItem>
                                  ),
                                )
                            }
                          </Select>
                        </FormControl>
                      </Box>
                      <FormControl sx={{ width: '100%', gap: 2 }}>
                        { /* Signature date */ }
                        <FromDateToDate
                          legend={t('accounts_list_signatureDate')}
                          fromDateLabel={<Trans>from_date</Trans>}
                          toDatelabel={<Trans>to_date</Trans>}
                          fromDateValue={dateFieldsValues['signatureDate[after]']}
                          toDateValue={dateFieldsValues['signatureDate[before]']}
                          handleDateValueChange={this.handleDateValueChange}
                          fieldNames={['signatureDate[after]', 'signatureDate[before]']}
                          displayInLine
                        />

                        { /* Connection date */ }
                        <FromDateToDate
                          legend={t('connection_date')}
                          fromDateLabel={<Trans>from_date</Trans>}
                          toDatelabel={<Trans>to_date</Trans>}
                          fromDateValue={dateFieldsValues['lastLoginAt[after]']}
                          toDateValue={dateFieldsValues['lastLoginAt[before]']}
                          handleDateValueChange={this.handleDateValueChange}
                          fieldNames={['lastLoginAt[after]', 'lastLoginAt[before]']}
                          displayInLine
                        />

                        { /* Video Upload date */ }
                        <FromDateToDate
                          legend={t('account_advanced_search_upload_date')}
                          fromDateLabel={<Trans>from_date</Trans>}
                          toDatelabel={<Trans>to_date</Trans>}
                          fromDateValue={dateFieldsValues['videoUploadedAt[after]']}
                          toDateValue={dateFieldsValues['videoUploadedAt[before]']}
                          handleDateValueChange={this.handleDateValueChange}
                          fieldNames={['videoUploadedAt[after]', 'videoUploadedAt[before]']}
                          displayInLine
                        />

                        <Box sx={{ display: 'flex', alignContent: 'center' }}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={hidden}
                                onChange={(_: ChangeEvent<HTMLInputElement>, checked: boolean) => this.setState({ hidden: checked })}
                              />
                            )}
                            label={t('account_advanced_search_hidden_accounts')}
                          />
                        </Box>
                      </FormControl>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button sx={{ margin: 1 }} onClick={this.clearFilters}>
                        <Trans>clear_filters</Trans>
                      </Button>
                      <Button
                        sx={{ margin: 1 }}
                        onClick={this.applyFilters}
                        variant="contained"
                      >
                        <Trans>apply</Trans>
                      </Button>
                    </Box>
                  </Box>
                </Drawer>
              );
            }
          }
        </ReferentialContext.Consumer>
      </>
    );
  }

  private handleFiltersSideBar = (isOpen: boolean) => {
    if (isOpen) {
      const { dateFieldsValues } = this.state;
      const dateFieldValuesCopy = { ...dateFieldsValues };
      const params = createParamsObjectFromQuerystring();
      const currentStepParams = params.getAll('currentStep[]');

      for (const [paramName, paramValue] of Array.from(params.entries())) {
        if (Object.keys(dateFieldsValues).includes(paramName)) {
          dateFieldValuesCopy[paramName as keyof IAccountsAdvancedSearchState['dateFieldsValues']] = parse(paramValue, 'dd-MM-yyyy', new Date());
        }
      }

      return this.setState({
        dateFieldsValues: {
          ...dateFieldsValues,
          ...dateFieldValuesCopy,
        },
        currentSteps: currentStepParams,
        filtersSideBarIsOpen: isOpen,
      });
    }

    this.setState({ filtersSideBarIsOpen: isOpen });
  };

  private handleMultipleCheckMarksChange = (event: SelectChangeEvent<string[]>, field: 'currentSteps' | 'advisors') => {
    const value = event.target.value as string[];

    this.setState((prevState) => ({
      ...prevState,
      [field as string]: value,
    }));
  };

  private handleSelect = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;

    this.setState({
      providerContractTypeId: value,
    });
  };

  private applyFilters = () => {
    const { getUserInvestmentAccountInformations } = this.props;
    const { dateFieldsValues, hidden, currentSteps, advisors, providerContractTypeId } = this.state;
    const params = new URLSearchParams({});

    for (const key in dateFieldsValues) {
      if (Object.prototype.hasOwnProperty.call(dateFieldsValues, key)) {
        const value = dateFieldsValues[key as keyof IAccountsAdvancedSearchState['dateFieldsValues']];

        params.append(key, value ? format(new Date(value), 'dd-MM-yyyy') : '');
      }
    }

    if (currentSteps.length === 0) {
      params.append('currentStep[]', '');
    }

    for (const currentStep of currentSteps) {
      params.append('currentStep[]', currentStep);
    }

    if (advisors.length === 0) {
      params.append('advisorId[]', '');
    }

    for (const advisor of advisors) {
      params.append('advisorId[]', advisor);
    }

    params.append('providerContractTypeId', providerContractTypeId ?? '');
    params.append('hidden', hidden ? hidden.toString() : '');

    getUserInvestmentAccountInformations(params);
    this.setState({
      filtersSideBarIsOpen: false,
    });
  };

  private handleDateValueChange = (fieldName: keyof IAccountsAdvancedSearchState['dateFieldsValues']) => (event: Date | null) => {
    if (!event) {
      this.setState(
        (previousState) => ({
          dateFieldsValues: {
            ...previousState.dateFieldsValues,
            [fieldName]: undefined,
          },
        }),
      );

      return;
    }

    isValid(new Date(event)) && this.setState(
      (previousState) => ({
        dateFieldsValues: {
          ...previousState.dateFieldsValues,
          [fieldName]: event,
        },
      }),
    );
  };

  private clearFilters = (): void => {
    const { updateUrl } = this.props;
    const params = createParamsObjectFromQuerystring();
    const { dateFieldsValues } = this.state;
    const paramsKeys = Object.keys(dateFieldsValues) as (keyof IAccountsAdvancedSearchState['dateFieldsValues'])[];

    this.setState({
      currentSteps: [],
      advisors: [],
      dateFieldsValues: paramsKeys.reduce<IAccountsAdvancedSearchState['dateFieldsValues']>((acc, key) => {
        acc[key] = undefined;

        return acc;
      }, {}),
      providerContractTypeId: '',
    });

    for (const key of paramsKeys) {
      if (params.has(key)) {
        params.delete(key);
      }
    }

    updateUrl(params);
  };

  private getRefLabel(items: string[], referential: IReferential[]) {
    return referential.filter((ref) => items.includes(ref.slug)).map((ref) => ref.label).join(', ');
  }

  private getProviderName(contract: string, providers: IProviders): string {
    return providers.find((provider) => provider['@id'] === contract)?.name ?? t('provider_unknown');
  }
}
